<script>
import {axiosInstance} from '../../../helpers/axios'
export default {
  name:"SifreSifirlama",
  data() {
    return {
      kullaniciadiniz:"",
      email:"",
    }
  },
  methods: {
    SifreSifirla(){
      var vm = this;
      try {
        if(vm.kullaniciadiniz=="" || vm.email=="")
        {
          this.$bvToast.toast("Lütfen Tüm Alanlari Doldurunuz", {
                        title: `Hata !`,
                        variant:"warning",
                        solid: true
          });
        }else{

          axiosInstance.post("kullanici/yetkili/sifresifirlama",{
            kullaniciadi:vm.kullaniciadiniz,
            email:vm.email,

          }).then((response) => {

            if(response.data.error==true)
            {
                this.$bvToast.toast(response.data.message, {
                            title: `Uyari !`,
                            variant:"warning",
                            solid: true
                });

            }else{

              this.$bvToast.toast("Sifreniz E-mail adresinize gönderilmistir", {
                          title: `Uyari !`,
                          variant:"info",
                          solid: true
              });

            }

          }).catch((error) => {
              this.$bvToast.toast("Hata Olustu.Lutfen Tekrar Deneyiniz.."+error, {
                          title: `Hata !`,
                          variant:"warning",
                          solid: true
              });
          })

        }
        
      } catch (error) {
        this.$bvToast.toast("Hata Olustu.Lutfen Tekrar Deneyiniz..", {
                        title: `Hata !`,
                        variant:"warning",
                        solid: true
        });
      }
    }
  },
}
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo.png" height="80" alt="logo" />
                          </a>
                        </div>
                        <br>
                        <p class="text-muted">Sifrenizi Sifirlamak icin bilgilerinizi yaziniz</p>
                      </div>

                      <div class="p-2 mt-5">
                        <span class="form-horizontal">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Email Adresiniz"
                              v-on:keyup.enter="SifreSifirla"
                            />
                          </div>
                           <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-file-user-fill auti-custom-input-icon"></i>
                            <label for="useremail">Kullanici Adiniz</label>
                            <input
                              type="text"
                              v-model="kullaniciadiniz"
                              class="form-control"
                              placeholder="Kullanici Adiniz"
                              v-on:keyup.enter="SifreSifirla"
                            />
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              @click="SifreSifirla"
                              v-on:keyup.enter="SifreSifirla"
                            >Sifirla</button>
                          </div>
                        </span>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Hesabiniz Mevcutsa Giris Yapmak icin 
                          <router-link tag="a"
                            to="/giris"
                            class="font-weight-medium text-primary"
                          >Tiklayiniz</router-link>
                        </p>
                        <div class="mt-5 text-center">
                          <p>
                            © 2021 Subgate Hotspot
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg" :style="{'background-image': 'url(' + require('../../../assets/images/background.jpg') + ')'}">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
